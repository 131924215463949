<template>
  <div class="set-page">
    <div class="set-password">
      <div class="password">
        <el-form
          ref="form"
          :rules="rules"
          class="form"
          :model="form"
          label-width="80px"
        >
          <el-form-item label="手机号:" prop="mobile">
            <el-input
              v-model="form.mobile"
              maxlength="11"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码:" prop="captcha">
            <div class="set_right flex">
              <el-input
                class="m-r-15"
                maxlength="6"
                v-model="form.captcha"
                placeholder="请输入验证码"
              ></el-input>
              <el-button :loading="disabled" @click="getCode">{{ codeTxt }}</el-button>
            </div>
          </el-form-item>
          <el-form-item label="密码:" prop="password">
            <el-input
              type="password"
              autocomplete="off"
              v-model="form.password"
              placeholder="请输入登录密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="pwd">
            <el-input
              type="password"
              autocomplete="off"
              v-model="form.pwd"
              placeholder="请确认登录密码"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="passwrod-bot">
          <el-button @click="submit('form')">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetPassword",
  data() {
    var validateMoblie = (rule, value, callback) => {
      if (this.form.mobile.match(/^(0|86|17951)?1[3456789]\d{9}$/)) {
        callback();
      } else {
        callback(new Error("请输入正确的手机号"));
      }
    };
    var validatePwd = (rule, value, callback) => {
      if (this.form.password == this.form.pwd) {
        callback();
      } else {
        callback(new Error("两次输入的密码不一致"));
      }
    };
    return {
      form: {
        mobile: "",
        password: "",
        captcha: "",
        pwd: "",
      },
      codeTxt: '获取验证码',
      sending: true,
      second: 60,
      disabled: false,
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateMoblie, trigger: "blur" },
        ],
        captcha:[
          { required: true, message: "请输入验证码", trigger: "blur" }
        ],
        password:[
           { required: true, message: "请输入登录密码", trigger: "blur" }
        ],
        pwd:[
          { required: true, message: "请输入确认登录密码", trigger: "blur" },
          { validator: validatePwd, trigger: "blur" },
        ]
      },
    };
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.passwordForget(this.form).then((res) => {
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.$store.commit("REMOVE_TOKEN")
            this.$router.push({path:'/login'})
          });
        } else {
          return false;
        }
      });
    },
    //获取验证码
    getCode () {
      if (!this.form.mobile || !this.form.mobile.match(/^(0|86|17951)?1[3456789]\d{9}$/)) return this.$message({
          message: '请输入正确的手机号',
          type: 'warning'
        });
      if (!this.sending) return
      this.sending = false;
      this.$axios.captcha({
        type:1,
        mobile:this.form.mobile
      }).then(res=>{
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.timeDown()
        } else {
          this.$message.error(res.msg);
        }
				this.disabled = true;
      })
    },
    timeDown() {
      let result = setInterval(() => {
        --this.second;
        this.codeTxt = this.second + 'S'
        if (this.second < 0) {
          clearInterval(result);
          this.sending = true;
          this.disabled = false;
          this.second = 60;
          this.codeTxt = '获取验证码'
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="less">
.set-password {
  background: #fff;
  padding: 50px;
  .password {
    width: 480px;
    margin: 0 auto;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    font-size: 16px;
    border-radius: 0px;
  }
  /deep/ .el-form-item__label {
    font-size: 16px;
    color: #666;
  }
  .passwrod-bot {
    padding: 40px 0px;
    /deep/ .el-button {
      width: 480px;
      height: 56px;
      color: #fff;
      font-size: 18px;
      background: #3273f6;
      border-radius: 28px;
    }
  }
}
.set_right {
  /deep/ .el-button {
    color: #ffffff;
    font-size: 16px;
    height: 40px;
    background: #3273f6;
  }
}
</style>
